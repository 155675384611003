<template>
  <div :class="{'image-container': true, active: selected}">
    <div class="check">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" fill="currentColor" /></svg>
    </div>
    <img v-bind:src="require('@/assets/' + option.imageUrl + '/' + option.artNumber + '.png')" v-bind:alt="option.name">
  </div>
</template>

<script>
export default {
  name: 'option-item',
  props: {
    option: Object,
    selected: Boolean
  },
  data () {
    return {}
  },
  computed: {
  },
  methods: {
    getImgUrl(option) {
      require('@/assets/'.concat(option.type,'/',option.artNumber,'.png'))
    }
  },
  watch: {},
  mounted() {},
}
</script>

<style scoped lang="sass">
@import "@/assets/styles/_variables.sass"
@import "@/assets/styles/_mixins.sass"
.image-container
  width: 25%
  height: 100%
  margin: auto
  border: 3px solid #EEEEEE
  padding: 6px
  position: relative
  background: #fff
  transition: border-color 175ms linear
  img
    display: block
    margin: 0 auto
.check
  display: flex
  justify-content: center
  align-items: center
  color: #fff
  background: $red
  position: absolute
  top: -3px
  right: -3px
  padding: 6px
  opacity: 0
  svg
    width: 11px
    height: 11px
.image-container.active
  border-color: $red
  .check
    opacity: 1
</style>
